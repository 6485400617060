.how-it-works,
.info {
  min-height: 90vh;
  display: flex;
  align-items: center;

  &__title {
    font-weight: 900;
    margin: 40px 0;
  }

  &__list {
    list-style: disc;
    font-weight: 400;
    list-style-position: inside;
    margin-bottom: 40px;

    li {
      margin-bottom: 20px;

      span {
        color: $orange;
      }
    }
  }

  &__goback {
    display: inline-block;
    background: $orange;
    border: none;
    border-radius: 5px;
    padding: 0 15px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-weight: 700;
    color: $white;
    font-size: 14px;
    cursor: pointer;
    min-width: 200px;
    text-decoration: none;

    &:hover {
      color: $white;
    }
  }
}

.info {
  text-align: center;
}