@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,600');

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');


@font-face {
  font-family: 'Seat Condensed';
  src: url('/fonts/SeatBcn-Web-UltraLightCondensedItalic.eot');
  src: url('/fonts/SeatBcn-Web-UltraLightCondensedItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/SeatBcn-Web-UltraLightCondensedItalic.woff2') format('woff2'),
      url('/fonts/SeatBcn-Web-UltraLightCondensedItalic.woff') format('woff'),
      url('/fonts/SeatBcn-Web-UltraLightCondensedItalic.ttf') format('truetype'),
      url('/fonts/SeatBcn-Web-UltraLightCondensedItalic.svg#SeatBcn-Web-UltraLightCondensedItalic') format('svg');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Seat Condensed';
  src: url('/fonts/SeatBcn-Web-MediumCondensedItalic.eot');
  src: url('/fonts/SeatBcn-Web-MediumCondensedItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/SeatBcn-Web-MediumCondensedItalic.woff2') format('woff2'),
      url('/fonts/SeatBcn-Web-MediumCondensedItalic.woff') format('woff'),
      url('/fonts/SeatBcn-Web-MediumCondensedItalic.ttf') format('truetype'),
      url('/fonts/SeatBcn-Web-MediumCondensedItalic.svg#SeatBcn-Web-MediumCondensedItalic') format('svg');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Seat';
  src: url('/fonts/SeatBcn-Web-BookItalic.eot');
  src: url('/fonts/SeatBcn-Web-BookItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/SeatBcn-Web-BookItalic.woff2') format('woff2'),
      url('/fonts/SeatBcn-Web-BookItalic.woff') format('woff'),
      url('/fonts/SeatBcn-Web-BookItalic.ttf') format('truetype'),
      url('/fonts/SeatBcn-Web-BookItalic.svg#SeatBcn-Web-BookItalic') format('svg');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Seat';
  src: url('/fonts/SeatBcn-Web-Light.eot');
  src: url('/fonts/SeatBcn-Web-Light.eot?#iefix') format('embedded-opentype'),
      url('/fonts/SeatBcn-Web-Light.woff2') format('woff2'),
      url('/fonts/SeatBcn-Web-Light.woff') format('woff'),
      url('/fonts/SeatBcn-Web-Light.ttf') format('truetype'),
      url('/fonts/SeatBcn-Web-Light.svg#SeatBcn-Web-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Seat';
  src: url('/fonts/SeatBcn-Web-Medium.eot');
  src: url('/fonts/SeatBcn-Web-Medium.eot?#iefix') format('embedded-opentype'),
      url('/fonts/SeatBcn-Web-Medium.woff2') format('woff2'),
      url('/fonts/SeatBcn-Web-Medium.woff') format('woff'),
      url('/fonts/SeatBcn-Web-Medium.ttf') format('truetype'),
      url('/fonts/SeatBcn-Web-Medium.svg#SeatBcn-Web-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Seat';
  src: url('/fonts/SeatBcn-Web-Italic.eot');
  src: url('/fonts/SeatBcn-Web-Italic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/SeatBcn-Web-Italic.woff2') format('woff2'),
      url('/fonts/SeatBcn-Web-Italic.woff') format('woff'),
      url('/fonts/SeatBcn-Web-Italic.ttf') format('truetype'),
      url('/fonts/SeatBcn-Web-Italic.svg#SeatBcn-Web-Italic') format('svg');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Seat Condensed';
  src: url('/fonts/SeatBcn-Web-UltraLightCondensed.eot');
  src: url('/fonts/SeatBcn-Web-UltraLightCondensed.eot?#iefix') format('embedded-opentype'),
      url('/fonts/SeatBcn-Web-UltraLightCondensed.woff2') format('woff2'),
      url('/fonts/SeatBcn-Web-UltraLightCondensed.woff') format('woff'),
      url('/fonts/SeatBcn-Web-UltraLightCondensed.ttf') format('truetype'),
      url('/fonts/SeatBcn-Web-UltraLightCondensed.svg#SeatBcn-Web-UltraLightCondensed') format('svg');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Seat';
  src: url('/fonts/SeatBcn-Web-UltraLightItalic.eot');
  src: url('/fonts/SeatBcn-Web-UltraLightItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/SeatBcn-Web-UltraLightItalic.woff2') format('woff2'),
      url('/fonts/SeatBcn-Web-UltraLightItalic.woff') format('woff'),
      url('/fonts/SeatBcn-Web-UltraLightItalic.ttf') format('truetype'),
      url('/fonts/SeatBcn-Web-UltraLightItalic.svg#SeatBcn-Web-UltraLightItalic') format('svg');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Seat';
  src: url('/fonts/SeatBcn-Web-Book.eot');
  src: url('/fonts/SeatBcn-Web-Book.eot?#iefix') format('embedded-opentype'),
      url('/fonts/SeatBcn-Web-Book.woff2') format('woff2'),
      url('/fonts/SeatBcn-Web-Book.woff') format('woff'),
      url('/fonts/SeatBcn-Web-Book.ttf') format('truetype'),
      url('/fonts/SeatBcn-Web-Book.svg#SeatBcn-Web-Book') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Seat';
  src: url('/fonts/SeatBcn-Web-LightItalic.eot');
  src: url('/fonts/SeatBcn-Web-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/SeatBcn-Web-LightItalic.woff2') format('woff2'),
      url('/fonts/SeatBcn-Web-LightItalic.woff') format('woff'),
      url('/fonts/SeatBcn-Web-LightItalic.ttf') format('truetype'),
      url('/fonts/SeatBcn-Web-LightItalic.svg#SeatBcn-Web-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Seat Condensed';
  src: url('/fonts/SeatBcn-Web-Condensed.eot');
  src: url('/fonts/SeatBcn-Web-Condensed.eot?#iefix') format('embedded-opentype'),
      url('/fonts/SeatBcn-Web-Condensed.woff2') format('woff2'),
      url('/fonts/SeatBcn-Web-Condensed.woff') format('woff'),
      url('/fonts/SeatBcn-Web-Condensed.ttf') format('truetype'),
      url('/fonts/SeatBcn-Web-Condensed.svg#SeatBcn-Web-Condensed') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Seat Condensed';
  src: url('/fonts/SeatBcn-Web-BoldCondensed.eot');
  src: url('/fonts/SeatBcn-Web-BoldCondensed.eot?#iefix') format('embedded-opentype'),
      url('/fonts/SeatBcn-Web-BoldCondensed.woff2') format('woff2'),
      url('/fonts/SeatBcn-Web-BoldCondensed.woff') format('woff'),
      url('/fonts/SeatBcn-Web-BoldCondensed.ttf') format('truetype'),
      url('/fonts/SeatBcn-Web-BoldCondensed.svg#SeatBcn-Web-BoldCondensed') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Seat';
  src: url('/fonts/SeatBcn-Web-Bold.eot');
  src: url('/fonts/SeatBcn-Web-Bold.eot?#iefix') format('embedded-opentype'),
      url('/fonts/SeatBcn-Web-Bold.woff2') format('woff2'),
      url('/fonts/SeatBcn-Web-Bold.woff') format('woff'),
      url('/fonts/SeatBcn-Web-Bold.ttf') format('truetype'),
      url('/fonts/SeatBcn-Web-Bold.svg#SeatBcn-Web-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Seat Condensed';
  src: url('/fonts/SeatBcn-Web-BlackCondensed.eot');
  src: url('/fonts/SeatBcn-Web-BlackCondensed.eot?#iefix') format('embedded-opentype'),
      url('/fonts/SeatBcn-Web-BlackCondensed.woff2') format('woff2'),
      url('/fonts/SeatBcn-Web-BlackCondensed.woff') format('woff'),
      url('/fonts/SeatBcn-Web-BlackCondensed.ttf') format('truetype'),
      url('/fonts/SeatBcn-Web-BlackCondensed.svg#SeatBcn-Web-BlackCondensed') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Seat Condensed';
  src: url('/fonts/SeatBcn-Web-BlackCondensedItalic.eot');
  src: url('/fonts/SeatBcn-Web-BlackCondensedItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/SeatBcn-Web-BlackCondensedItalic.woff2') format('woff2'),
      url('/fonts/SeatBcn-Web-BlackCondensedItalic.woff') format('woff'),
      url('/fonts/SeatBcn-Web-BlackCondensedItalic.ttf') format('truetype'),
      url('/fonts/SeatBcn-Web-BlackCondensedItalic.svg#SeatBcn-Web-BlackCondensedItalic') format('svg');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Seat';
  src: url('/fonts/SeatBcn-Web-Regular.eot');
  src: url('/fonts/SeatBcn-Web-Regular.eot?#iefix') format('embedded-opentype'),
      url('/fonts/SeatBcn-Web-Regular.woff2') format('woff2'),
      url('/fonts/SeatBcn-Web-Regular.woff') format('woff'),
      url('/fonts/SeatBcn-Web-Regular.ttf') format('truetype'),
      url('/fonts/SeatBcn-Web-Regular.svg#SeatBcn-Web-Regular') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Seat';
  src: url('/fonts/SeatBcn-Web-Black.eot');
  src: url('/fonts/SeatBcn-Web-Black.eot?#iefix') format('embedded-opentype'),
      url('/fonts/SeatBcn-Web-Black.woff2') format('woff2'),
      url('/fonts/SeatBcn-Web-Black.woff') format('woff'),
      url('/fonts/SeatBcn-Web-Black.ttf') format('truetype'),
      url('/fonts/SeatBcn-Web-Black.svg#SeatBcn-Web-Black') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Seat Condensed';
  src: url('/fonts/SeatBcn-Web-BoldCondensedItalic.eot');
  src: url('/fonts/SeatBcn-Web-BoldCondensedItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/SeatBcn-Web-BoldCondensedItalic.woff2') format('woff2'),
      url('/fonts/SeatBcn-Web-BoldCondensedItalic.woff') format('woff'),
      url('/fonts/SeatBcn-Web-BoldCondensedItalic.ttf') format('truetype'),
      url('/fonts/SeatBcn-Web-BoldCondensedItalic.svg#SeatBcn-Web-BoldCondensedItalic') format('svg');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Seat Condensed';
  src: url('/fonts/SeatBcn-Web-BookCondensedItalic.eot');
  src: url('/fonts/SeatBcn-Web-BookCondensedItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/SeatBcn-Web-BookCondensedItalic.woff2') format('woff2'),
      url('/fonts/SeatBcn-Web-BookCondensedItalic.woff') format('woff'),
      url('/fonts/SeatBcn-Web-BookCondensedItalic.ttf') format('truetype'),
      url('/fonts/SeatBcn-Web-BookCondensedItalic.svg#SeatBcn-Web-BookCondensedItalic') format('svg');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Seat';
  src: url('/fonts/SeatBcn-Web-UltraLight.eot');
  src: url('/fonts/SeatBcn-Web-UltraLight.eot?#iefix') format('embedded-opentype'),
      url('/fonts/SeatBcn-Web-UltraLight.woff2') format('woff2'),
      url('/fonts/SeatBcn-Web-UltraLight.woff') format('woff'),
      url('/fonts/SeatBcn-Web-UltraLight.ttf') format('truetype'),
      url('/fonts/SeatBcn-Web-UltraLight.svg#SeatBcn-Web-UltraLight') format('svg');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Seat Condensed';
  src: url('/fonts/SeatBcn-Web-CondensedItalic.eot');
  src: url('/fonts/SeatBcn-Web-CondensedItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/SeatBcn-Web-CondensedItalic.woff2') format('woff2'),
      url('/fonts/SeatBcn-Web-CondensedItalic.woff') format('woff'),
      url('/fonts/SeatBcn-Web-CondensedItalic.ttf') format('truetype'),
      url('/fonts/SeatBcn-Web-CondensedItalic.svg#SeatBcn-Web-CondensedItalic') format('svg');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Seat';
  src: url('/fonts/SeatBcn-Web-BlackItalic.eot');
  src: url('/fonts/SeatBcn-Web-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/SeatBcn-Web-BlackItalic.woff2') format('woff2'),
      url('/fonts/SeatBcn-Web-BlackItalic.woff') format('woff'),
      url('/fonts/SeatBcn-Web-BlackItalic.ttf') format('truetype'),
      url('/fonts/SeatBcn-Web-BlackItalic.svg#SeatBcn-Web-BlackItalic') format('svg');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Seat Condensed';
  src: url('/fonts/SeatBcn-Web-LightCondensedItalic.eot');
  src: url('/fonts/SeatBcn-Web-LightCondensedItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/SeatBcn-Web-LightCondensedItalic.woff2') format('woff2'),
      url('/fonts/SeatBcn-Web-LightCondensedItalic.woff') format('woff'),
      url('/fonts/SeatBcn-Web-LightCondensedItalic.ttf') format('truetype'),
      url('/fonts/SeatBcn-Web-LightCondensedItalic.svg#SeatBcn-Web-LightCondensedItalic') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Seat';
  src: url('/fonts/SeatBcn-Web-MediumItalic.eot');
  src: url('/fonts/SeatBcn-Web-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/SeatBcn-Web-MediumItalic.woff2') format('woff2'),
      url('/fonts/SeatBcn-Web-MediumItalic.woff') format('woff'),
      url('/fonts/SeatBcn-Web-MediumItalic.ttf') format('truetype'),
      url('/fonts/SeatBcn-Web-MediumItalic.svg#SeatBcn-Web-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Seat Condensed';
  src: url('/fonts/SeatBcn-Web-MediumCondensed.eot');
  src: url('/fonts/SeatBcn-Web-MediumCondensed.eot?#iefix') format('embedded-opentype'),
      url('/fonts/SeatBcn-Web-MediumCondensed.woff2') format('woff2'),
      url('/fonts/SeatBcn-Web-MediumCondensed.woff') format('woff'),
      url('/fonts/SeatBcn-Web-MediumCondensed.ttf') format('truetype'),
      url('/fonts/SeatBcn-Web-MediumCondensed.svg#SeatBcn-Web-MediumCondensed') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Seat Condensed';
  src: url('/fonts/SeatBcn-Web-BookCondensed.eot');
  src: url('/fonts/SeatBcn-Web-BookCondensed.eot?#iefix') format('embedded-opentype'),
      url('/fonts/SeatBcn-Web-BookCondensed.woff2') format('woff2'),
      url('/fonts/SeatBcn-Web-BookCondensed.woff') format('woff'),
      url('/fonts/SeatBcn-Web-BookCondensed.ttf') format('truetype'),
      url('/fonts/SeatBcn-Web-BookCondensed.svg#SeatBcn-Web-BookCondensed') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Seat';
  src: url('/fonts/SeatBcn-Web-BoldItalic.eot');
  src: url('/fonts/SeatBcn-Web-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/SeatBcn-Web-BoldItalic.woff2') format('woff2'),
      url('/fonts/SeatBcn-Web-BoldItalic.woff') format('woff'),
      url('/fonts/SeatBcn-Web-BoldItalic.ttf') format('truetype'),
      url('/fonts/SeatBcn-Web-BoldItalic.svg#SeatBcn-Web-BoldItalic') format('svg');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Seat Condensed';
  src: url('/fonts/SeatBcn-Web-LightCondensed.eot');
  src: url('/fonts/SeatBcn-Web-LightCondensed.eot?#iefix') format('embedded-opentype'),
      url('/fonts/SeatBcn-Web-LightCondensed.woff2') format('woff2'),
      url('/fonts/SeatBcn-Web-LightCondensed.woff') format('woff'),
      url('/fonts/SeatBcn-Web-LightCondensed.ttf') format('truetype'),
      url('/fonts/SeatBcn-Web-LightCondensed.svg#SeatBcn-Web-LightCondensed') format('svg');
  font-weight: 300;
  font-style: normal;
}


