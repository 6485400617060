.product {
  @include trans;

  margin-bottom: 40px;
  padding: 20px;

  &:hover {
    background: $lightestGray;
  }
  &__image {
    width: 100%;
    overflow: hidden;
    height: 0;
    padding-bottom: 50%;
    position: relative;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: auto;
      height: 100%;
    }
  }

  &__content {
    color: $darkGray;
    font-family: "Seat";
    font-weight: 400;
  }

  &__title {
    color: $black;
    font-family: $mainFont;
  }

  &__text {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-bottom: 20px;

    .-big {
      display: block;
      font-size: 16px;
      font-weight: 700;
      margin-top: 5px;
    }
  }

  &__text-small {
    font-size: 10px;
    display: flex;
    align-items: flex-start;
    font-weight: 700;

    p {
      margin: 0;
      margin-right: 5px;
      margin-bottom: 5px;
    }

    .-subindex {
      font-size: 6px;
      transform: translateY(-2px);
    }
  }

  &__buttons {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__button {
    display: block;
    color: $white;
    background: $orange;
    text-decoration: none;
    border: 2px solid $orange;
    border-radius: 5px;
    width: 48%;
    padding: 15px 0;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    
    &:hover {
      color: $white;
      background: darken($orange, 10%);
    }

    &--ghost {
      background: $white;
      color: $orange;

      &:hover {
        color: $orange;
        background: rgba(0,0,0,.1);
      }
    }
  }
}