.home {
  &__hero {

    &__slider {
      margin-bottom: 0 !important;

      .slick-dots {
        bottom: 0;

        li {
          button {
            &:before {
              content: '';
              width: 20px;
              height: 2px;
              background: $white;
            }
          }
        }
      }
    }

    &__slide-group {
      @include mq(md) {
        display: flex;
      }
    }
    
    &__slide-image {
      width: 100%;
      height: 0;
      overflow: hidden;
      padding-bottom: 69%;
      position: relative;

      @include mq(md) {
        width: 65%;
        padding-bottom: 31%;
        order: 2;
      }

      .-desktop {
        display: none;

        @include mq(md) {
          display: block;
        }
      }

      .-mobile {
        @include mq(md) {
          display: none;
        }      
      }
  
      img {
        position: absolute;
        height: auto;
        width: 100%;
        
        @include mq(md) {
          height: auto;
          width: 100%;

        }
      }
    }

    &__slide-content {
      background: $red;
      padding: 40px;
      min-height: 32vh;

      @include mq(md) {
        width: 35%;
        padding: 0 100px;
        order: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
    }

    &__slide-title {
      font-weight: 900;
      margin-top: 0;
      margin-bottom: 20px;
      font-size: 26px;

      @include mq(md) {
        font-size: 30px;
      }
    }

    &__slide-text {
      font-size: 14px;
      line-height: 1.5;
      font-weight: 400;

      @include mq(md) {
        font-size: 16px;
      }
    }
  }

  &__products {

    &__wrapper {
      
      @include mq(md) {
        display: flex;
        justify-content: space-between;
      }
    }

    &__filter-group {
      border-bottom: 1px solid $lightGray;
      position: relative;

      @include mq(md) {
        display: flex;
        align-items: center;
        border-bottom: 0;
      }
    }

    &__filter-selector {
      padding: 30px 0;
      text-align: center;
      text-transform: uppercase;
      font-weight: 700;
      position: relative;
      width: 30%;
      display: block;
      margin-left: auto;
      margin-right: auto;
      cursor: pointer;

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 45%;
        width: 8px;
        height: 8px;
        border-left: 1px solid $black;
        border-bottom: 1px solid $black;
        transform: rotate(-45deg) translateY(-50%);
      }

      @include mq(md) {
        display: none;
      }
    }

    &__filters {
      @include trans;

      height: 0;
      opacity: 0;
      overflow: hidden;

      &.-open {
        height: 220px;
        opacity: 1;
      }
      
      @include mq(md) {
        display: flex;
        margin-left: 40px;
        height: auto;
        opacity: 1;
      }

      li {
        color: $black;
        font-weight: 700;
        font-size: 12px;
        cursor: pointer;
        height: 40px;
        line-height: 40px;
        position: relative;
        padding: 0 15px;

        &:after {
          @include trans;

          content: '';
          height: 3px;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 100%;
          background: $black;
        }

        &:not(:last-child) {
          @include mq(md) {
            margin-right: 30px;
          }
        }

        @include mq(md) {
          padding: 0 3px;
          height: 70px;
          line-height: 70px;
        }

        &.-active {
          &:after {
            right: 0;
          }
        }
      }
    }

    &__file {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 40px 20px 0;
      color: $darkestGray;
      text-decoration: none;
      
      @include mq(md) {
        border-left: 1px solid $lightGray;

      }

      p {
        margin: 0;
        font-size: 14px;
        color: $darkestGray;
        text-decoration: none;
      }
    }

    &__file-image {
      width: 30px;
      margin: 0 20px;

      img {
        height: auto;
        width: 100%;
      }
    }

    &__leyend {
      margin-bottom: 40px;
      
      @include mq(md) {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      p {
        color: $darkGray;
        font-size: 12px;
        font-weight: 700;
        margin: 0;
        text-align: center;

        @include mq(md) {
          text-align: left;
        }

        &:not(:last-child) {
          margin-right: 20px;
          margin-bottom: 10px;

          @include mq(md) {
            margin-bottom: 0;
          }
        }
      }
    }

    &__item {
      &.-hidden {
        display: none;
      }
    }
  }
}

.-divider {
  height: 1px;
  background: $lightGray;
  width: 100%;
}