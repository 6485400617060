.contacto {
  min-height: 90vh;
  display: flex;
  align-items: center;

  &__form {
    padding: 10% 0;

    @include mq(sm) {
      padding: 7% 30px;
    }

    &__group {
      width: 100%;
    }

    &__input {
      border: none;
      border-bottom: 1px solid $lightGray;
      margin-bottom: 40px;
      width: 100%;
      padding-bottom: 10px;
      outline: none;
      height: 40px;
      font-family: 'Seat', sans-serif;
    }
    
    
    &__submit {
      display: block;
      background: $orange;
      border: none;
      border-radius: 5px;
      padding: 15px 0;
      text-align: center;
      font-weight: 700;
      color: $white;
      font-size: 14px;
      width: 200px;
      cursor: pointer;
    }
  }
}