.footer {
  background: $darkestGray;
  padding: 40px 0;
  
  @include mq(md) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__list {
    text-align: center;
    margin-bottom: 40px;

    @include mq(md) {
      text-align: left;
      margin-left: 40px;
      display: flex;
      margin-bottom: 0;
    }
    
    li {
      margin-bottom: 20px;

      @include mq(md) {
        margin-right: 40px;
        margin-bottom: 0;
      }
    }
    
    a {
      color: $white;
      text-decoration: none;

      &:hover {
        color: $orange;
      }
      
      @include mq(md) {
        font-size: 12px;
      }
    }
  }

  &__social {
    margin-right: 40px;
    display: flex;
    justify-content: center;
    
    li {
      margin-left: 30px;
    }
    
    i {
      color: $white;
      font-size: 30px;

      @include mq(md) {
        font-size: 24px;
      }
    }
  }
}
